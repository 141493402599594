import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
    redirect:"/main",
    children:[
      {
        path: '/main',
        name: 'main',
        component: () => import(/* webpackChunkName: "about" */ '../views/main.vue'),
      },
      {
        path: '/product/list',
        name: 'productList',
        component: () => import(/* webpackChunkName: "about" */ '../views/goods/GoodList.vue'),

      },
      {
        path: '/product/import',
        name: 'product',
        component: () => import(/* webpackChunkName: "about" */ '../views/goods/GoodImport.vue'),
      },
      {
        path: '/product/add',
        name: 'productAdd',
        component: () => import(/* webpackChunkName: "about" */ '../views/goods/AddGood.vue'),
      },
      {
        path: '/product/details',
        name: 'goodDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/goods/GoodDetails.vue'),
      },
      {
        path: '/product/nodup',
        name: 'productNodup',
        component: () => import(/* webpackChunkName: "about" */ '../views/goods/GoodNodup.vue'),

      },
      {
        path: '/stock/outlist',
        name: 'stockOutList',
        component: () => import(/* webpackChunkName: "about" */ '../views/stock/StockOutList.vue'),
      },
      {
        path: '/stock/check',
        name: 'stockCheck',
        component: () => import(/* webpackChunkName: "about" */ '../views/stock/StockCheck.vue'),
      },
      {
        path: '/stock/input',
        name: 'stockInput',
        component: () => import(/* webpackChunkName: "about" */ '../views/stock/StockInput.vue'),
      },
      {
        path: '/stock/warn/list',
        name: 'stockWarn',
        component: () => import(/* webpackChunkName: "about" */ '../views/stock/StockWarn.vue'),

      },
      {
        path: '/sale/list/ser',
        name: 'stockListSer',
        component: () => import(/* webpackChunkName: "about" */ '../views/sale/SaleListSer.vue'),
      },
      {
        path: '/sale/list/noser',
        name: 'stockListNoSer',
        component: () => import(/* webpackChunkName: "about" */ '../views/sale/SaleListNoSer.vue'),
      },
      {
        path: '/sale/list/mt',
        name: 'MtListNoSer',
        component: () => import(/* webpackChunkName: "about" */ '../views/sale/MtSaleList.vue'),
      },
      {
        path: '/sale/order/refund',
        name: 'MtListNoSer',
        component: () => import(/* webpackChunkName: "about" */ '../views/sale/Refund.vue'),
      },
      {
        path: '/sale/analyse',
        name: 'saleAnalyse',
        component: () => import(/* webpackChunkName: "about" */ '../views/sale/SaleAnalyse'),
      },
      {
        path: '/sale/mend/sale',
        name: 'MendSale',
        component: () => import(/* webpackChunkName: "about" */ '../views/sale/MendSale.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {

  // 判断有没有登录
  if (!sessionStorage.getItem('token')) {
    if (to.name == "login") {
      next();
    } else {
      router.push('/login')
    }
  } else {
    next();
  }
});
export default router
